.services {
  padding: 60px 0 0;

  @media(--medium-viewport) {
    padding: 100px 0 0;
  }

  &__image {
    background: center no-repeat;
    background-size: cover;

    height: 192px;
    margin: 0 0 60px;

    @media(--medium-viewport) {
      height: 372px;
    }
  }

  &__text {
    margin: 0 0 40px;
    overflow: hidden;
    padding: 0;
    text-align: justify;

    @media(--medium-viewport) {
      lost-column: 12/18;
      lost-offset: -3/18;
    }

    @media(--wide-viewport) {
      lost-column: 10/18;
      lost-offset: -4/18;
    }

    h1 {
      @media(--medium-viewport) {
        margin: 0 0 35px;
      }
    }
  }
}
