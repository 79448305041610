$alternating-break-one: 600px;

.hero + .alternating {
	margin-top: -41px;
}

.alternating {
	background-color: #F2F2F2;
	padding: 1.5rem 0;

	@media (min-width: $alternating-break-one) {
		padding: 2rem 0;
	}

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}

	&__item {
		width: 100%;
		margin-bottom: 1rem;

		@media (min-width: $alternating-break-one) {
			width: 55%;
			margin-bottom: 0;
		}

		&--image {
			width: 100%;
			display: none;
			margin-bottom: 0;

			@media (min-width: $alternating-break-one) {
				display: block;
				width: 45%;
				padding-left: 50px;
			}

			img {
				display: block;
				width: 100%;
			}
		}
	}

	&__heading {
		font-size: 1.25rem;
		margin: 0;

		@media (min-width: $alternating-break-one) {
			text-align: left;
		}
	}

	&__copy {
		margin: 0.5rem 0 0;
		text-align: center;

		@media (min-width: $alternating-break-one) {
			text-align: left;
		}
	}

	.alternating__button {
		margin: 1.2rem 0 0;

		@media (min-width: $alternating-break-one) {
			left: 0;
			transform: unset;
			text-align: left;
		}
	}
}
