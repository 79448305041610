$assets-font-path: "/assets/fonts" !default;

@font-face {
  font-family: 'icomoon';
  src:  url('#{$assets-font-path}/icomoon.eot?ow5zo');
  src:  url('#{$assets-font-path}/icomoon.eot?ow5zo#iefix') format('embedded-opentype'),
  url('#{$assets-font-path}/icomoon.ttf?ow5zo') format('truetype'),
  url('#{$assets-font-path}/icomoon.woff?ow5zo') format('woff'),
  url('#{$assets-font-path}/icomoon.svg?ow5zo#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-BG_Logo_Mobile_1x:before {
  content: "\e900";
}
.icon-BG_Logo_Mobile_2x:before {
  content: "\e901";
}
.icon-BG_Logo_Regular_1x:before {
  content: "\e902";
}
.icon-BG_Logo_Regular_2x:before {
  content: "\e903";
}
.icon-BG_Logo_Small_1x:before {
  content: "\e904";
}

.icon-BG_Logo_Small_2x {
    aspect-ratio: 1;
    background-image: url("/assets/img/bg-logo-small.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

.icon-BG_Logo_Small_2x:before {
  display: none;
}

.icon-Nav_Menu_Icon:before {
  content: "\e906";
}
.icon-Nav_Menu_Cross:before {
  content: "\e907";
}
.icon-Link_Arrow_Left:before {
  content: "\e908";
}
.icon-Link_Arrow_Right:before {
  content: "\e909";
}
.icon-Carousel_Arrow_Left:before {
  content: "\e90a";
}
.icon-Carousel_Arrow_Left_Hover:before {
  content: "\e90b";
}
.icon-Carousel_Arrow_Right:before {
  content: "\e90c";
}
.icon-Carousel_Arrow_Right_Hover:before {
  content: "\e90d";
}
.icon-Down_Arrow_Reg:before {
  content: "\e90e";
}
.icon-Down_Arrow_Sml:before {
  content: "\e90f";
}
.icon-Back_To_Top:before {
  content: "\e910";
}
.icon-Speech_Marks_Begin:before {
  content: "\e911";
}
.icon-Speech_Marks_Finish:before {
  content: "\e912";
}
.icon-Map_Pin:before {
  content: "\e913";
}
.icon-Mail_Icon:before {
  content: "\e914";
}
.icon-Phone_Icon:before {
  content: "\e915";
}
.icon-Facebook_Icon_No_Fill:before {
  content: "\e916";
}
.icon-Facebook_Icon:before {
  content: "\e917";
}
.icon-Twitter_Icon_No_Fill:before {
  content: "\e918";
}
.icon-Twitter_Icon:before {
  content: "\e919";
}

