.cards {
	margin-top: 1rem;

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		list-style: none;
		margin: auto;
		max-width: 940px;
		padding: 0 20px;
	}

	&__item {
		width: 100%;
		text-align: center;
		margin: 1.5rem 0 0;

		@media (min-width: 500px) {
			width: calc(50% - 10px);
			margin: 2.5rem 0 0;
		}
	}

	&__link {
		text-decoration: none;
	}

	&__image {
		display: block;
		width: 100%;

		img {
			display: block;
			width: 100%;
		}
	}

	&__heading {
		margin-top: 2ex;
	}
}

