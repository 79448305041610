.gallery__row--flex + .container.container--narrow {
  margin-top: 3rem;
}


.featured-project,
.featured-project-small {
  &__type {
    color: $c-brand-1;
    font-family: $font-milo;
    font-size: rem(11px);
    letter-spacing: rem(1.18px);
    line-height: 2.55;
    margin: 0 0 24px;
    text-align: center;
    text-transform: uppercase;

    @media(--medium-viewport) {
      font-size: rem(14px);
      letter-spacing: rem(1.5px);
      line-height: 2;
    }

    a {
      color: inherit;
    }
  }
}

.featured-project {
  &__image {
    margin-bottom: 12px;

    @media(--medium-viewport) {
      lost-column: 9/18;

      margin-bottom: 60px;
    }

    .project__thumb {
      margin-bottom: 0;
    }
  }

  &__type {
    @media(--medium-viewport) {
      text-align: left;
    }
  }

  &__details {
    @media(--medium-viewport) {
      lost-column: 8/18;
      lost-offset: -1/18;

      padding: 37px 0 0;
    }

    @media(--large-viewport) {
      padding: 0;
    }

    @media(--wide-viewport) {
      padding: 0 0 0;
    }

    @media (min-width: 1420px) {
      padding: 0 0 0 !important;
    }
  }

  &__title {
    @media(--medium-viewport) {
      font-size: rem(24px);
      letter-spacing: rem(2.57px);
      line-height: 1.29;
      margin: 0 0 10px;
      text-align: left;
    }
  }

  &__content {
    display: none;
    overflow: hidden;

    @media(--large-viewport) {
      display: block;
      margin: 0 0 8px;
    }

    p {
      color: $c-brand-1;
      text-align: justify;
    }
  }

  &__more {
    display: none;

    @media(--medium-viewport) {
      display: inline-block;
    }
  }

  &__button {
    @media(--medium-viewport) {
      margin: 35px 0 0;
    }
  }
}

.featured-project-small {
  @media(--medium-viewport) {
    lost-column: 6/18;
  }

  &__thumb {
    margin: 0 0 12px;
  }
}

.projects {
  clear: both;
  overflow: hidden;
  padding-bottom: 60px;

  @media(--large-viewport) {
    padding-bottom: 100px;
  }
}

.project-small {
  lost-column: 1/2 2;

  .caption {
    margin-top: 6px;

    p {
      margin: 0;
      color: #4f5961;
    }
  }
}


.project {
  padding: 40px 0 0;

  @media(--medium-viewport) {
    padding: 60px 0 0;
  }

  @media(--large-viewport) {
    padding: 100px 0 0;
  }

  &__title {
    padding: 0;
    text-align: left;

    @media(--medium-viewport) {
      lost-column: 17/18;
      lost-offset: -1/18;
    }

    &:after {
      content: none;
    }
  }

  &__intro {
    text-align: justify;

    @media(--medium-viewport) {
      lost-column: 10/18;
      lost-offset: -1/18;
    }

    @media(--wide-viewport) {
      lost-column: 9/18;
      lost-offset: -1/18;
    }
  }

  &__note {
    @media(--medium-viewport) {
      lost-column: 5/18;
      lost-offset: -1/18;
    }

    @media(--wide-viewport) {
      lost-column: 5/18;
      lost-offset: -2/18;
    }
  }

  &__nav {
    background: $c-brand-10;
  }

  .lookbook--alt {
    background: $c-brand-11;
  }
}

.photo-credit {
  margin-bottom: 30px;

  @media(--wide-viewport) {
    margin: 10px 0 30px;
  }

  &__link {
    color: $c-brand-5;
    display: block;
    font-family: $font-museo;
    font-size: rem(11px);
    line-height: 2;

    &:after {
      content: "\e90f";
      display: inline-block;
      font-family: 'icomoon'!important;
      font-size: rem(6px);
      margin-left: 10px;
    }
  }

  &__content {
    display: none;

    p {
      font-size: rem(11px);
      line-height: 2.27;
    }
  }


  &.open {
    .photo-credit__link {
      &:after {
        transform: rotateX(180deg);
      }
    }

    .photo-credit__content {
      display: block;
    }
  }
}

.note {
  margin: 0 0 30px;

  &__title {
    font-size: rem(12px);
    letter-spacing: rem(1.2px);
    line-height: 2.08;
    margin: 0 0 20px;
    text-align: left;

    @media(--medium-viewport) {
      font-size: rem(14px);
      letter-spacing: rem(1.4px);
      line-height: 1.79;
    }
  }

  p {
    color: $c-brand-1;
    font-family: $font-open-sans;
    font-size: rem(12px);
    font-style: italic;
    line-height: 2.17;

    @media(--medium-viewport) {
      font-size: rem(14px);
      line-height: 1.86;
    }
  }
}

.gallery {
  margin: 0 0 30px;

  &__row,
  &__portrait,
  &__landscape {
    div {
      background: no-repeat center;
      background-size: cover;
      clear: both;
      margin-bottom: 10px;
      overflow: hidden;

      @media(--medium-viewport) {
        margin-bottom: 14px;
      }
    }
  }

  &__row {
    div {
      height: 200px;

      @media(--medium-viewport) {
        height: 607px;
      }

      @media(--wide-viewport) {
        height: 867px;
      }
    }

    &--double {
      div {
        @media(--medium-viewport) {
          lost-column: 1/2;
        }
      }
    }

    &--triple {
      div {
        @media(--medium-viewport) {
          lost-column: 1/3;
        }
      }
    }


    &--flex {
      display: flex;
      gap: 14px;
      margin: 0 0 14px;
      max-width: 100%;

      img {
        border: none;
        vertical-align: bottom;
        min-width: 0;
      }

      .img {
        border: none;
        vertical-align: bottom;
        min-width: 0;
        
        .caption {
          	margin-top: 8px;
        }
      }
    }

    &--overflow {
      overflow: auto;
    }
  }

  &__portrait,
  &__landscape,
  &__video--landscape {
    @media(--medium-viewport) {
      height: 400px !important;
    }

    @media(--wide-viewport) {
      height: 577px !important;
    }
  }

  &__portrait {
    height: 405px !important;

    @media(--medium-viewport) {
      lost-column: 6/18;
    }
  }

  &__landscape,
  &__video--landscape {
    @media(--medium-viewport) {
      lost-column: 12/18;
    }
  }

  &__loadmore {
    margin: 0 0 30px;
  }

  .container {
    padding-bottom: 20px;
  }

  &__video {
    overflow: hidden;
    position: relative;
    width: 100%;

    &--landscape {
      height: 405px !important;

      @media(--medium-viewport) {
        lost-column: 12/18;
      }
    }

    iframe,
    object,
    embed {
      height: 100% !important;
      left: 0;
      position: absolute;
      top: 0;
      width: 100% !important;
    }
  }

}

.gallery-img-wrapper {
  background-size: cover;
  background-position: 50%;

  img {
    height: 0;
    width: 0;
    overflow: hidden;
  }

  &--portrait {
      aspect-ratio: 0.66;
  }

  &--landscape {}
}

.prev-project {
  display: none;

  @media(--wide-viewport) {
    lost-column: 5/18;

    display: block;
    overflow: hidden;
    position: relative;
  }

  &__title {
    font-size: rem(18px);
    margin: 0 0 40px;
    padding: 0;
    text-align: left;

    &:after {
      content: none;
    }
  }

  &__subtitle {
    font-size: rem(24px);
    margin: 0 0 10px;
    text-align: left;
  }
}

.next-project {
  overflow: hidden;
  padding: 30px 0;

  @media(--medium-viewport) {
    padding: 60px 0;
  }

  @media(--large-viewport) {
    padding: 80px 0 100px;
  }

  @media(--wide-viewport) {
    lost-column: 13/18;
  }

  &__container {
    display: flex;
    flex-flow: column-reverse;

    @media(--medium-viewport) {
      display: block;
      flex-direction: unset;
    }
  }

  &__title {
    @media(--medium-viewport) {
      lost-column: 8/18;
      lost-offset: -1/18;

      margin: 0 0 55px;
      padding: 0;
      text-align: left;
    }

    &:after {
      @media(--medium-viewport) {
        content: none;
      }
    }
  }

  &__subtitle {
    margin: 10px 0 10px 10px;
    text-align: left;

    @media(--medium-viewport) {
      font-size: rem(24px);
      letter-spacing: rem(2.57px);
      line-height: 1.29;
      margin: 0 0 15px;
    }
  }

  &__thumbnail {
    @media(--medium-viewport) {
      lost-column: 9/18;
    }

    @media(--wide-viewport) {
      lost-column: 8/18;
      lost-offset: -1/18;

      position: relative;
    }

    &:after {
      @media(--wide-viewport) {
        content: '';
        border-left: solid 1px $c-brand-5;
        position: absolute;
        height: 100%;
        top: 0;
        left: calc(-10% + 14px);
      }
    }
  }
  &__details {
    @media(--medium-viewport) {
      lost-column: 8/18;
      lost-offset: -1/18;
    }
  }

  &__subtitle,
  &__link {
    margin-left: 10px;

    @media(--medium-viewport) {
      margin-left: 0;
    }
  }
}

.project-nav {
  &__type,
  &__description {
    display: none;

    @media(--large-viewport) {
      display: block;
    }
  }

  &__type {
    font-family: $font-milo;
    font-size: rem(14px);
    margin: 0 0 45px;
    text-transform: uppercase;
  }

  &__description {
    overflow: hidden;
    margin: 0 0 20px;
  }
}