/* fonts */

$font-milo: 'FFMiloSerifWeb', sans-serif;
$font-museo: 'MuseoSans-300', serif;
$font-open-sans: 'Open Sans', sans-serif;

/* colours */

$c-link: #B2BBC0;
$c-sc-link: #00d1b6;
$c-bkg-main: #fdfdfc;
$c-bkg-hero: rgba(#353535, 0.4);
$c-bkg-saveitem: rgba($c-link, 0.4);
$c-brand-1: #505961;
$c-brand-2: #4A4A4A;
$c-brand-3: #ADADAD;
$c-brand-4: #f2f2f2;
$c-brand-5: #979797;
$c-brand-6: #8A8A8A;
$c-brand-7: #F4F4F4;
$c-brand-8: #FFFFF8;
$c-brand-9: #A9A9A9;
$c-brand-10: #f2f2f2;
$c-brand-11: #f5f5f5;
$c-brand-12: #D9D9D9;
$c-brand-13: #eaeaea;
$c-brand-14: #B5A39C;
$c-form-1: #E2E2E2;

$base-font-size: 16px;