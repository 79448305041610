$timeline-break-one: 600px;

.timeline {
	margin-bottom: 6rem;
	padding-bottom: 6rem;
	position: relative;

	&::after {
		content: "";
		width: 1px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		background-color: $c-brand-1;
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 1;
		margin-bottom: 3rem;
		border-bottom: 1rem solid #fdfdfc;

		@media (min-width: $timeline-break-one) {
			border-bottom: unset;

			&:nth-of-type(even) {
				flex-direction: row-reverse;
			}

			&::after {
				position: absolute;
				content: "";
				top: calc(50% - 0.4rem);
				left: calc(50% - 0.4rem);
				width: 0.8rem;
				height: 0.8rem;
				border-radius: 100%;
				background-color: $c-link;
			}
		}

	}

	&__copy {
		text-align: center;
		width: 100%;
		background-color: #fdfdfc;
		padding-top: 0.5rem;

		@media (min-width: $timeline-break-one) {
			padding-top: unset;
			width: calc(50% - 2rem);
		}

		h2 {
			font-size: 1.5rem;
		}
	}

	&__image {
		display: block;
		width: 100%;

		@media (min-width: $timeline-break-one) {
			width: calc(50% - 2rem);
		}

		img {
			display: block;
			width: 100%;
		}
	}
}

