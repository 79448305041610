.intro-block {
  text-align: center;
  max-width: 720px;
  margin: auto;
  padding: 0 20px;

  &__logo {
      color: $c-brand-1;
      display: block;
      margin: 60px auto 30px;
      width: 6rem;
      height: 6rem;
  }

  p {
    color: #505961;
    font-family: FFMiloSerifWeb, sans-serif;
    text-transform: uppercase;
    line-height: 1.9;
    letter-spacing: 0.15ex;
  }
}

.caption {
  &--home-hero {}

  p {
    font-size: 0.7rem;
  }
}

.home-hero {
  padding: 0;

  @media(--medium-viewport) {
    padding: 10px 0 0;
  }

  &__image {
    background: no-repeat center;
    background-size: cover;
    height: calc(100vh - 70px - 265px);

    @media(--medium-viewport) {
      height: calc(100vh - 80px - 275px);
      lost-center: 706px;
    }

    @media(--large-viewport) {
      lost-center: 910px;
    }

    @media(--wide-viewport) {
      lost-center: 1980px;
    }
  }

}

.home-prophire {
  @media(--medium-viewport) {
    padding: 60px 0 40px;
  }

  @media(--large-viewport) {
    padding: 80px 0px;
  }

  @media(--wide-viewport) {
    padding: 80px 0px 100px;
  }

  &__container {
    clear: both;
    overflow: hidden;
  }

  &__title,
  &__description,
  &__thumbs {
    margin-left: auto;
    margin-right: auto;
    width: 93.33333%;

    @media(--medium-viewport) {
      width: calc(99.9% * 10 / 18 - 6.22222px);
    }
  }

  &__title{
    @media(--large-viewport) {
      padding: 0;
      text-align: left;
      width: auto;
    }

    &:after {
      @media(--large-viewport) {
        content: none;
      }
    }
  }

  &__thumbs {
    @media(--large-viewport) {
      lost-column: 11/18;
    }

    @media(--wide-viewport) {
      lost-column: 12/18;
    }
  }

  &__thumb {
    overflow: hidden;
    background: no-repeat center;
    background-size: cover;
    height: 220px;

    @media(--medium-viewport) {
      height: 284px;
    }

    @media(--large-viewport) {
      lost-column: 6/11;

      display: block;
      height: 242px;
    }

    @media(--wide-viewport) {
      lost-column: 7/12;

      height: 351px;
    }

    &:last-child {
      @media(--medium-viewport) {
        display: none;
      }

      @media(--large-viewport) {
        display: block;
      }
    }

    &--last {
      @media(--large-viewport) {
        lost-column: 5/11;
      }

      @media(--wide-viewport) {
        lost-column: 5/12;
      }
    }
  }

  &__description {
    padding: 20px 0 0;

    @media(--large-viewport) {
      lost-column: 6/18;
      lost-offset: -1/18;

      padding: 0;
    }

    @media(--wide-viewport) {
      lost-column: 5/18;
      lost-offset: -1/18;

      padding: 55px 0 0;
    }

    p {
      text-align: justify;
    }
  }

  &__link {
    padding: 14px 0 0;
    text-align: center !important;

    @media(--medium-viewport) {
      text-align: left !important;
    }

    a {
      display: block;
    }
  }
}

.badges {
	background: $c-brand-4;

	&__row {
		margin: 0 -7px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	&__badge {
		padding: 7px;
		flex: 0 0 50%;

		@media(--medium-viewport) {
			flex: 0 0 20%;
		}

		@media(--large-viewport) {
			flex: 0 0 20%;
		}

		@media(--wide-viewport) {
			flex: 0 0 10%;
		}
	}

	&__image {
		max-width: 100%;
		height: auto;
	}
}