    .footer {
    p {
        text-align: center;
    }

    &__info {
        background: $c-brand-1;
        padding: 40px 0;
    }

    &__company,
    &__tagline {
        color: $c-brand-4;
        text-transform: uppercase;
    }

    &__company {
        font-family: $font-milo;
        font-size: rem(18px);
        letter-spacing: rem(1.8px);
        line-height: 1.22;
        margin: 0 0 4px;

        @media(--medium-viewport) {
            font-size: rem(20px);
            letter-spacing: rem(2px);
            line-height: 1.2;
        }
    }

    &__tagline {
        font-size: rem(11px);
        letter-spacing: rem(0.55px);
        line-height: 1.18;
        margin: 0 0 40px;

        @media(--medium-viewport) {
            font-size: rem(14px);
            letter-spacing: rem(0.7px);
            line-height: 1.21;
        }
    }

    &__logo {
        margin-bottom: 12px;
        text-align: center;

        @media(--medium-viewport) {
            margin-bottom: 17px;
        }

        a {
            color: #fff;
        }

        span {
            width: 80px;
            display: block;
            margin: auto;
            background-image: url("/assets/img/bg-logo-small-white.svg");
        }
    }

    &__contact {
        margin: 0 0 rem(10px);

        p, a {
            color: $c-brand-3;
            font-size: rem(12px);
            letter-spacing: rem(0.75px);
            line-height: 1.83;
            margin: 0;

            @media(--medium-viewport) {
                font-size: rem(14px);
                letter-spacing: rem(0.88px);
                line-height: 1.71;
            }
        }
    }

    &__social {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 0;
        text-align: center;

        li {
            font-size: rem(12px);
			display: inline-block;
			vertical-align: middle;
        }

    }

    &__social-icon {
        width: 32px;
        height: 32px;

        path {
            fill: $c-brand-14;
        }
    }

    &__credits {
        margin: 6px 0;
        padding: 0;

        @media(--medium-viewport) {
            clear: both;
            overflow: hidden;
            padding: 0 62px;
        }
    }

    &__copy,
    &__credit {
        color: $c-brand-3;
        font-size: rem(10px);
        line-height: 2.4;
        margin: 0;

        @media(--medium-viewport) {
            display: inline-block;
            font-size: rem(12px);
            line-height: 2;
        }
    }

    &__copy {
        @media(--medium-viewport) {
            float: left;
        }
    }

    &__credit {
        @media(--medium-viewport) {
            float: right;
        }

        a {
            color: inherit;

            &:hover {
                color: $c-sc-link;
            }
        }
    }
}
