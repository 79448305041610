.lookbook {
  padding: 0 0 60px;

  @media(--medium-viewport) {
    padding: 0 0 100px;
  }

  &__nav {
    position: relative;
  }

  &__filter {
    @extend %filter-link;

    @media(--medium-viewport) {
      display: none;
    }
  }

  &__categories {
    @extend %filter-list;

    @media(--medium-viewport) {
      position: static;
      display: block;
    }
  }

  &__subcategories {
    display: none;

    @media(--medium-viewport) {
      display: block;
    }
  }

  &__list {
    lost-masonry-wrap: no-flex;
  }

  &__item {
    lost-masonry-column: 1/2 10px;

    margin-bottom: 10px;

    @media(--medium-viewport) {
      lost-masonry-column: 1/3 14px;
      margin-bottom: 14px;
    }

    &--quote {
      display: none;

      @media(--wide-viewport) {
        display: block;
        padding: 80px 20px;
        position: relative;
      }

      &:before,
      &:after {
        font-family: 'icomoon' !important;
        font-size: rem(20px);
        position: absolute;
      }

      &:before {
        content: "\e911";
        top: 10px;
        left: 20px;
      }

      &:after {
        content: "\e912";
        bottom: 10px;
        right: 20px;
      }

      p {
        font-family: $font-milo;
        font-size: rem(28px);
        font-weight: 400;
        letter-spacing: rem(2.98px);
        line-height: 1.2;
        margin: 0;
        text-align: center;
      }
    }
  }

  &__more,
  &__loading {
    margin-top: 20px;

    @media(--medium-viewport) {
      margin-top: 60px;
    }
  }
}