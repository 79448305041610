/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

.hamburger {
  position: relative;
  float: right;
  padding: 14px 7px 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: $c-brand-5;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  line-height: 1;

  @media(--medium-viewport) {
    padding: 20px 0 0;
  }

  @media(--wide-viewport) {
    display: none;
  }

  &:hover {
    opacity: 0.4;
  }
}

.hamburger-box {
  width: 26px;
  height: 16px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 26px;
  height: 2px;
  background-color: $c-brand-5;
  border-radius: 1px;
  position: absolute;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -7px;
}

.hamburger-inner::after {
  bottom: -7px;
}

/*
 * Squeeze
 */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.1s 0.14s ease, opacity 0.1s ease;
}

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.1s 0.14s ease, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger {
  &.is-active {
    color: $c-brand-5;

    .hamburger-inner {
      background-color: $c-brand-5;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transition-delay: 0.14s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &:before {
        background-color: $c-brand-5;
        top: 0;
        opacity: 0;
        transition: top 0.1s ease, opacity 0.1s 0.14s ease;
      }

      &:after {
        background-color: $c-brand-5;
        bottom: 0;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        transition: bottom 0.1s ease, -webkit-transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);

      }
    }

  }
}
