/**
 * --------
 * HELPERS
 * --------
 */

%structural-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

%small-link {
  color: $c-link;
  display: inline-block;
  font-size: rem(11px);
  font-weight: 300;
  line-height: 2.27;
}

%filter-link {
  color: $c-brand-5;
  display: block;
  font-family: $font-open-sans;
  font-size: rem(14px);
  font-style: italic;
  margin: 0 0 10px;
  padding: 0 0 20px;
  position: relative;
  text-align: center;

  @media(--large-viewport) {
    display: none;
  }

  &:after {
    content: "\e90f";
    display: block;
    font-family: icomoon;
    font-size: rem(5px);
    font-style: normal;
    margin-top: -15px;
    position: absolute;
    top: 100%;
    width: 100%;
  }

  &.open {
    &:after {
      transform: rotateX(180deg);
    }
  }
}

%filter-list {
  background: $c-bkg-main;
  display: none;
  left: 0;
  padding: 10px 0 30px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 5;

  @media(--large-viewport) {
    position: static;
    display: block;
  }

  &.open {
    display: block;
  }
}

.intro {
	text-align: center;
}

/**
 * -----
 * MISC
 * -----
 */

.ajax-loading {
  display: none;

  img {
    display: block;
    height: 32px;
    margin: 0 auto;
    width: 32px;
  }
}

.carousel {
  position: relative;

  @media(--wide-viewport) {
    padding-bottom: 40px;
  }

  &__dots {
    li {
      height: 10px !important;
      margin: 0 6px !important;
      padding: 0 !important;
      width: 10px !important;

      button {
        background: transparent !important;
        border: 1px solid $c-brand-5 !important;
        border-radius: 50% !important;
        height: 10px !important;
        padding: 0 !important;
        width: 10px !important;

        &:before,
        &:after {
          content: none !important;
        }

        &:hover {
          background: $c-brand-5 !important;
        }
      }

      &.slick-active {
        button {
          background: $c-brand-5 !important;

          &:before {
            content: none  !important;
          }
        }
      }
    }

    &--prop {
      bottom: 10px !important;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      button {
        border: 1px solid $c-brand-8 !important;

        &:hover {
          background: $c-brand-8 !important;
        }
      }

      &.slick-active {
        button {
          background: $c-brand-8 !important;
        }
      }
    }
  }

  /* Arrows */
  .slick-next,
  .slick-prev {
    color: $c-brand-8;
    font-size: 48px;
    width: 16px;
    height: 32px;
    cursor: pointer;
    top: 50%;
    left: 10px;
    margin-top: 0;
    position: absolute;
    z-index: 10;

    @media(--wide-viewport) {
      color: $c-brand-5;
      left: -36px;
      margin-top: -16px;
    }

    &:before {
      color: $c-brand-8;
      content: "\e90a";
      font-family: 'icomoon' !important;
      font-size: rem(32px);

      @media(--wide-viewport) {
        color: $c-brand-5;
      }
    }

    &--team {
      @media(--medium-viewport) {
        left: -36px;
      }

      &:before {
        color: $c-brand-5;
      }
    }
  }
  .slick-next {
    right: 10px;
    left: auto;

    @media(--wide-viewport) {
      left: auto;
      right: -36px;
    }

    &:before {
        content: "\e90c";
    }

    &--team {
      @media(--medium-viewport) {
        left: auto;
        right: -36px;
      }
    }
  }

  &:hover {
    .slick-next {
      &:before {
        content: "\e90d";
      }
    }

    .slick-prev {
      &:before {
        content: "\e90b";
      }
    }
  }

}

/**
 * ---------------
 * BUTTONS & LINKS
 * ---------------
 */

.btn {
  background: transparent;
  border: solid 1px $c-link;
  color: $c-link;
  cursor: pointer;
  display: inline-block;
  font-family: $font-museo;
  font-size: rem(14px);
  font-weight: 300;
  letter-spacing: rem(0.7px);
  line-height: 1.86;
  margin: 5px auto 0;
  padding: 8px 28px 4px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  text-transform: uppercase;

  &:hover {
    background: $c-link;
    color: $c-brand-8;
  }

  &--load {
    margin: 14px auto 0;

    @media(--medium-viewport) {
      margin: 20px auto 0;
    }

    @media(--large-viewport) {
      margin: 40px auto 0;
    }
  }

  &--form {
    background: $c-link;
    color: $c-brand-8;
    width: 100%;

    &:hover {
      background: $c-brand-4;
      border: solid 1px $c-brand-4;
      color: $c-link;
    }
  }

  &--narrow {
    width: auto;

    @media(--medium-viewport) {
      left: 0;
      transform: none;
    }
  }
}

.more {
  @extend %small-link;

  @media(--medium-viewport) {
    font-size: rem(14px);
    line-height: 1.57;
  }

  &:after {
    content: "\e909";
    font-family: 'icomoon' !important;
    font-size: rem(7px);
    line-height: 2.27;
    margin: 0 0 0 15px;

    @media(--medium-viewport) {
      font-size: rem(7px);
      line-height: 1.57;
      margin: 0 0 0 6px;
    }
  }
}

.back {
  @extend %small-link;

  @media(--medium-viewport) {
    font-size: rem(14px);
    line-height: 1.57;
  }

  &:before {
    content: "\e908";
    font-family: 'icomoon' !important;
    font-size: rem(7px);
    line-height: 2.27;
    margin: 0 10px 0 0;

    @media(--medium-viewport) {
      line-height: 1.57;
      margin: 0 6px 0 0;
    }

    @media(--large-viewport) {
      margin: 0 9px 0 0;
    }
  }
}

.readmore {
  &__button {
    display: block;
    font-size: rem(11px);
    line-height: 2.27;
    margin: 0 0 14px;
    text-align: center;

    @media(--medium-viewport) {
      font-size: rem(14px);
      line-height: 1.5;
      text-align: left;
    }
  }

}

.scroll-up {
  display: none;
  height: 137px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
  width: 13px;

  span {
    font-size: rem(132px);
  }
}

/**
 * ----------
 * CATEGORIES
 * ----------
 */

.categories {
  margin: 0 0 20px;
  padding: 0;
  text-align: center;

  @media(--wide-viewport) {
    margin: 0 0 40px;
  }

  li {
    display: inline-block;
    font-size: rem(15px);
    line-height: 1.2;
    margin: 0 4px;
    text-align: center;

    @media(--medium-viewport) {
      font-size: rem(16px);
      margin: 0 14px;
    }

    &:first-of-type {
      margin: 0 14px;

      &:after {
        content: "|";
        position: relative;
        right: -14px;
      }
    }
  }

  a {
    color: $c-brand-1;
    display: inline-block;
    padding: 0 4px 2px;

    @media(--medium-viewport) {
      padding: 0;
    }

    &:hover {
      color: $c-link;
      opacity: 1;
    }
  }

  .on a {
    border-bottom: solid 4px $c-brand-1;

    &:hover {
      border-bottom-color: $c-link;
      color: $c-link;
      opacity: 1;
    }
  }

  &--side,
  &--lookbook {
    padding: 16px 0 12px;

    li {
      display: block;
      margin: 0 0 20px;

      &:first-of-type {
        margin: 0 0 20px;

        &:after {
          content: none;
        }
      }
    }

    .on a {
      color: $c-link;
      border-bottom: none;
    }
  }

  &--side {
    li {
      @media(--medium-viewport) {
        margin: 0 0 10px;
      }

      @media(--large-viewport) {
        margin: 0 0 14px;
        text-align: left;
      }

      &:first-of-type {
        @media(--medium-viewport) {
          margin: 0 0 10px;
        }

        @media(--large-viewport) {
          display: none;
        }
      }
    }
  }

  &--lookbook {
    @media(--medium-viewport) {
      padding: 0;
    }

    li {
      @media(--medium-viewport) {
        display: inline-block;
        margin-bottom: 0;
      }

      &:first-of-type {
        @media(--medium-viewport) {
          margin: 0 14px;

          &:after {
            content: "|";
            position: relative;
            right: -14px;
          }
        }

        a {
          &:after {
            content: " Categories";

            @media(--medium-viewport) {
              content: none;
            }
          }
        }
      }
    }

    .on a {
      @media(--medium-viewport) {
        border-bottom: solid 4px $c-brand-1;
        color: $c-brand-1;
      }

      &:hover {
        border-bottom-color: $c-link;
        color: $c-link;
        opacity: 1;
      }
    }
  }

  &--children {
    @media(--medium-viewport) {
      background: $c-brand-1;
      margin-bottom: 20px;
      padding: 16px 0 14px;
    }

    li {
      @media(--medium-viewport) {
        margin: 0 10px;
      }

      &:first-of-type {
        margin: 0 4px;

        @media(--medium-viewport) {
          margin: 0 10px;
        }

        &:after {
          content: none;
        }
      }
    }


    a {
      color: $c-brand-8;
      font-size: rem(15px);

      &:hover {
        color: $c-brand-8;
        opacity: .6;
      }
    }

    .on a {
      @media(--medium-viewport) {
        border-bottom: solid 4px $c-brand-12;
      }

      &:hover {
        color: $c-brand-8;
        border-bottom: solid 4px $c-brand-12;
        opacity: .6;
      }
    }
  }
}

/**
 * -----------
 * PAGE HEROS
 * -----------
 */
.hero {
  background: no-repeat center;
  background-size: cover;
  height: 340px;
  margin: 0 0 20px;

  @media(--medium-viewport) {
    height: 430px;
    margin: 0 0 40px;
  }

  @media(--wide-viewport) {
    margin: 10px auto 40px;
    max-width: 1980px;
  }

  &__content {
    background: $c-bkg-hero;
    padding: 30px 18px 10px;

    @media(--medium-viewport) {
      padding: 35px 60px 10px;
    }

    @media(--large-viewport) {
      display: inline-block;
      margin: 116px 0 0 7%;
      padding: 24px 89px 28px 20px;
      width: 574px;
    }

    @media(--wide-viewport) {
      margin: 124px 0 0 7%;
    }

    h1, p {
      color: #fff;
    }

    h1 {
      font-size: rem(20px);
      letter-spacing: rem(1.58px);
      line-height: 1.1;
      margin: 0 0 14px;
      padding: 0;
      text-align: left;

      @media(--medium-viewport) {
        font-size: rem(38px);
        letter-spacing: rem(3px);
        line-height: 1.18;
        margin: 0 0 20px;
      }

      &:after {
        content: none;
      }
    }

    p {
      font-size: rem(12px);
      line-height: 1.33;

      @media(--medium-viewport) {
        font-size: rem(16px);
        line-height: 1.5;
      }
    }
  }
}

/**
 * -----------
 * QUOTES
 * -----------
 */
.quotes {
  background: $c-brand-4;
  padding: 60px 0 20px;

  blockquote {
    margin: 0;
    padding: 0;

    p {
      color: $c-brand-1;
      font-family: $font-open-sans;
      font-size: rem(12px);
      font-style: italic;
      line-height: 2.17;
      text-align: center;

      @media(--medium-viewport) {
        font-size: rem(14px);
        line-height: 1.86;
      }
    }

    footer {
      padding: 6px 0 12px;

      @media(--medium-viewport) {
        padding: 4px 0 15px;
      }
    }

    cite {
      color: $c-brand-6;
      display: block;
      font-family: $font-milo;
      font-size: rem(12px);
      font-style: normal;
      letter-spacing: rem(1.2px);
      line-height: 2.08;
      text-align: center;
      text-transform: uppercase;

      @media(--medium-viewport) {
        font-size: rem(14px);
        letter-spacing: rem(1.4px);
        line-height: 1.79;
      }
    }
  }

  &__list {
    visibility: hidden;
    margin: 0 auto;
    padding: 0 0 20px;
    width: 280px;

    @media(--medium-viewport) {
      width: 466px;
    }

    @media(--large-viewport) {
      width: 494px;
    }

    @media(--wide-viewport) {
      width: 570px;
    }

    &.slick-initialized { visibility: visible; }
  }

  .slick-slider {
    display: none!important;

    &.slick-initialized { display: block!important; }
  }
}

/**
 * -------------
 * FULL SERVICE
 * -------------
 */

.info-box {
  background: $c-brand-4;

  &--alt {
    background: $c-brand-7;
  }

  h3 {
    margin: 0 0 20px;

    @media(--medium-viewport) {
      margin: 0 0 30px;
    }
  }

  p {
    text-align: justify;
  }

  &__container {
    clear: both;
    overflow: hidden;
  }

  &__column {
    width: 93.33333%;
    margin: 0 auto 50px;

    @media(--medium-viewport) {
      width: calc(99.9% * 10 / 18 - 6.22222px);
    }

    @media(--large-viewport) {
      lost-column: 1/3;

      padding: 0 20px;
    }

    @media(--wide-viewport) {
      padding: 0 25px;
    }
  }

  &__title {
    @media(--medium-viewport) {
      font-size: rem(24px);
      letter-spacing: rem(2.57px);
      line-height: 1.29;
    }
  }

  &__button {
    margin: -25px 0 0;

    @media(--medium-viewport) {
      margin: 0;
    }
  }
}

/**
 * -------------
 * LOOK BOOK
 * -------------
 */

.lookbook-preview {
  padding: 60px 0 40px;

  @media(--large-viewport) {
    padding: 80px 0 70px;
  }

  @media(--wide-viewport) {
    padding: 80px 0 100px;
  }

  &--alt {
    background: $c-brand-13;
  }

  &__title {
    margin: 0 0 10px;
    text-align: left;

    @media(--medium-viewport) {
      margin: 0 0 20px;
    }

    @media(--medium-viewport) {
      margin: 0 0 30px;
    }

    &:after {
      content: none;
    }
  }

  &__thumbs {
    clear: both;
    margin: 0 0 20px;
    overflow: hidden;

    @media(--medium-viewport) {
      margin: 0 0 30px;
    }

    @media(--large-viewport) {
      lost-column: 12/18 1;
    }

    @media(--wide-viewport) {
      lost-column: 15/18 1;
    }
  }

  &__thumb {
    lost-column: 1/3;

    @media(--medium-viewport) {
      lost-column: 2/9;
    }

    @media(--large-viewport) {
      lost-column: 1/4 4;
    }

    @media(--wide-viewport) {
      lost-column: 1/5;
    }

    &:last-child {
      @media(--medium-viewport) {
        display: none;
      }

      @media(--wide-viewport) {
        display: block;
      }
    }
  }

  &__description {
    overflow: hidden;
    width: 93.33333%;

    @media(--large-viewport) {
      lost-column: 6/18;

      padding: 20px 0 0;
    }

    @media(--wide-viewport) {
      lost-column: 3/18;

      padding: 35px 0 0;
    }
  }

  &__link {
    padding: 18px 0 0;

    @media(--medium-viewport) {
      padding: 6px 0 0;
    }

    @media(--wide-viewport) {
      padding: 24px 0 0;
    }
  }
}