html,
body {
  height: 100%;
  margin: 0;
}

html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: $c-bkg-main;
  color: $c-brand-2;
  display: table;
  font-family: $font-museo;
  font-size: 100%;
  font-weight: normal;
  min-height: 100%;
  table-layout:fixed;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5 {
  font-family: $font-milo;
}

h1, h2 {
  color: $c-brand-1;
  font-weight: normal;
  position: relative;
  text-align: center;
  text-transform: uppercase;

  a {
    color: inherit;
  }
}

h1 {
  font-size: rem(18px);
  letter-spacing: rem(1.34px);
  line-height: 1.22;
  margin: 0 0 30px;
  padding: 0 0 18px;

  @media(--medium-viewport) {
    font-size: rem(28px);
    letter-spacing: rem(2.08px);
    line-height: 1.36;
    margin: 0 0 50px;
    padding: 0 0 18px;
  }

  &:after {
    border-bottom: solid 1px $c-brand-1;
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -25px;
    top: 100%;
    width: 50px;
  }
}

h2 {
  font-size: rem(16px);
  letter-spacing: rem(1.72px);
  line-height: 1.75;
  margin: 0;
  padding: 0;
}

p, li, address, label {
  font-family: $font-museo;
  font-size: rem(13px);
  font-weight: 300;
  line-height: 1.85;
  margin: 0 0 20px;
  padding: 0;

  @media(--medium-viewport) {
    font-size: rem(16px);
    line-height: 1.5;
  }
}

a {
  color: $c-link;
  text-decoration: none;

  &:hover {
    opacity: .6;

    figure {
      opacity: inherit;
    }
  }

  &:hover, &:active, &:focus {
    outline: 0;
  }
}

img {
  border: none;
  vertical-align: bottom;
  width: 100%;
}

button, div {
  &:active,
  &:focus,
  &:visited {
    outline: none;
  }
}

address {
  font-style: normal;
}

main {
  padding: 70px 0 0;

  @media(--medium-viewport) {
    padding: 80px 0 0;
  }
}

section {
  padding: 60px 0;

  @media(--large-viewport) {
    padding: 80px 0 100px;
  }
}

figure {
  display: block;
  margin: 0 0 20px;
  padding: 0;

  @media(--medium-viewport) {
    margin: 0 0 40px;
  }
}

figcaption {
  color: $c-brand-1;
  font-family: $font-milo;
  font-size: rem(11px);
  letter-spacing: rem(1.18px);
  line-height: 1.18;
  padding: 6px 0 0;
  text-align: center;
  text-transform: uppercase;

  @media(--medium-viewport) {
    font-size: rem(16px);
    letter-spacing: rem(1.72px);
    line-height: 1.75;
    padding: 12px 0 0;
  }
}

input, label, textarea {
  width: 100%;
}

label,
select {
  display: block;
}

label {
  margin: 0 0 5px;
}

input, textarea {
  border: solid 1px $c-form-1;
  margin: 0 0 20px;
}

input {
  height: 32px;
}

textarea {
  height: 80px;
}

#mfPreviewBar { display: none!important; }

.js-viewport {
  transform: translateY(1rem);
  opacity: 0;
  transition: opacity 0.3s, transform  0.4s;

    &--in-viewport {
      transform: translateY(0);
      opacity: 1;
    }
}