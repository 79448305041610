.saveditems {
  &__intro {
    margin: 0 0 15px;
    overflow: hidden;

    @media(--medium-viewport) {
      lost-column: 9/18;
    }

    @media(--large-viewport) {
      lost-column: 7/18;
      lost-offset: -2/18;

      margin: 0 0 30px;
    }

    @media(--wide-viewport) {
      lost-column: 6/18;
      lost-offset: -2/18;

      margin: 0 0 25px;
    }

    p {
      text-align: justify;
    }
  }

  &__send {
    margin: 0 0 50px;

    @media(--medium-viewport) {
      lost-column: 8/18;
      lost-offset: -1/18;
    }

    @media(--large-viewport) {
      lost-column: 6/18;
      lost-offset: -1/18;
    }

    @media(--wide-viewport) {
      lost-column: 4/18;
      lost-offset: -4/18;
    }

    p {
      margin: 0 0 10px;
    }
  }

  &__enquiry {
    display: none;
    padding: 40px 0;

    @media(--large-viewport) {
      lost-column: 14/18;
      lost-offset: -2/18;
    }
  }

  &__contact {
    font-size: rem(11px);
    text-align: center;

    @media(--medium-viewport) {
      font-size: rem(12px);
      text-align: left;
    }
  }

  &__basket {
    margin: 0 0 40px;
    @media(--large-viewport) {
      lost-column: 14/18;
      lost-offset: -2/18;
    }
  }

  &__list {
    list-style: none;
    overflow: hidden;
    padding: 0;
  }

  &__link {
    font-size: rem(11px);

    @media(--medium-viewport) {
      font-size: rem(14px);
      letter-spacing: rem(0.3px);
      line-height: 1.43;
    }

    @media(--large-viewport) {
      letter-spacing: 0;
    }
  }
}

.saveditem {
  clear: both;
  overflow: hidden;

  @media(--wide-viewport) {
    margin: 0 0 30px;
  }

  &__image {
    float: left;
    margin-right: 20px;
    width: 40px;

    @media(--large-viewport) {
      margin-right: 16px;
    }

    @media(--wide-viewport) {
      margin-right: 14px;
      width: 60px;
    }

  }

  &__details {
    overflow: hidden;

    p {
      margin: 0 0 3px;

      @media(--large-viewport) {
        margin: 0 0 6px;
      }

      @media(--wide-viewport) {
        margin: 0 0 8px;
      }
    }
  }

  &__title {
    @media(--large-viewport) {
      font-size: rem(14px);
    }
  }

  &__actions {
    overflow: hidden;
    p {
      float: left;
      margin-right: 10px;

      @media(--large-viewport) {
        margin-right: 14px;
      }

      @media(--wide-viewport) {
        margin-right: 24px;
      }
    }

    ul {
      display: block;
      list-style: none;
      margin: 0;
      overflow: hidden;
      padding: 0;
    }

    li {
      display: inline-block;
      margin: 0;

      &:last-of-type {
        float: right;
      }
    }
  }
}

.form {
  &__row {
    clear: both;
    overflow: hidden;
  }

  &__column {
    @media(--medium-viewport) {
      lost-column: 9/18;
    }

  }
}

#propHireEnquiryFormErrors,
#propHireEnquiryFormThanks,
#twitter {
  display: none;
}