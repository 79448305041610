$prop-hire-entry-break-one: 740px;

.prop-hire-entry {
  &__banner {
    background-color: $c-brand-14;
    position: sticky;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem 2.5rem;
    border-bottom: 2px solid #fff;

    @media (min-width: $prop-hire-entry-break-one) {
      padding: 0.8rem 1rem;
    }
  }

  &__banner-heading {
    color: #fff;
    font-size: 17px;
    line-height: 1.2;
    margin: 0 auto;

    @media (min-width: $prop-hire-entry-break-one) {
      font-size: 22px;
      text-align: left;
      margin: 0;
    }
  }

  .prop-hire-entry__banner-btn {
    background-color: #fff;
    color: #525960;
    transform: unset;
    left: unset;
    margin: 0.5rem auto;

    @media (min-width: $prop-hire-entry-break-one) {
      margin: 0.5rem 0;
    }
  }

  .services__text {
    p + h2 {
      margin: 4ex 0 1ex;
    }

    h2 {
      text-align: left;
      font-size: 22px;
      margin: 0 0 1ex;
    }
  }

  .gallery__row--flex {
    .img {
      width: 100%;
    }
  }

  .cards__container {
    max-width: 660px;
    margin-top: -2rem;

    @media (min-width: 1421px) {
      max-width: 760px;
    }
  }

  .cards__section-heading {
    width: 100%;
    text-align: left;
    font-size: 26px;
    margin-bottom: -1ex;
  }

  .help {
    h2 {
      margin-top: 4ex;
    }
  }
}

.prophire {
  padding: 0 0 60px;

  @media(--large-viewport) {
    padding: 0 0 100px;
  }

  &__sidebar {
    position: relative;

    @media(--large-viewport) {
      lost-column: 3/18;
    }
  }

  &__filter {
    @extend %filter-link;
  }

  &__categories {
    @extend %filter-list;
  }


  &__items {
    clear: both;

    @media(--large-viewport) {
      lost-column: 15/18;
    }
  }

  &__items-list {
    clear: both;
    overflow: hidden;
  }

  figure {
    margin: 0 0 14px;

    @media(--wide-viewport) {
      margin: 0;
    }
  }

  figcaption {
    font-family: $font-museo;
    font-size: rem(12px);
    letter-spacing: 0;
    line-height: 1.16;
    padding: 12px 0 0;
    text-transform: none;

    @media(--large-viewport) {
      font-size: rem(14px);
      letter-spacing: rem(0.3px);
      line-height: 1.43;
      text-align: left;
    }
  }
}

.item-small {
  lost-waffle: 1/2 2;

  @media(--large-viewport) {
    lost-waffle: 1/5 5;
  }
}

.prop {
  @media(--wide-viewport) {
    padding: 90px 0 5px;
  }

  &__imagery {
    margin: 0 !important;
    overflow: hidden;

    @media(--medium-viewport) {
      lost-column: 16/18;
      lost-offset: -1/18;

      padding-top: 10px;
    }

    @media(--large-viewport) {
      lost-column: 11/18;
      lost-offset: 0/18;
    }

    @media(--wide-viewport) {
      lost-column: 11/18;
      lost-offset: 0/18;
    }

    .slick-dotted.slick-slider {
      margin-bottom: 0;
		}
  }

  &__information {
    lost-center: 280px;

    @media(--medium-viewport) {
      max-width: none;

      lost-column: 8/18;
      lost-offset: -5/18;
    }

    @media(--large-viewport) {
      lost-column: 6/18;
      lost-offset: -1/18;
    }

    @media(--wide-viewport) {
      lost-column: 6/18;
      lost-offset: 0/18;
    }
  }

  &__image,
  &__carousel,
  &__suggestions {
    @media(--wide-viewport) {
      lost-column: 9/11;
      lost-offset: -1/11;
    }
  }

  &__suggestions {
    display: none;

    @media(--large-viewport) {
      lost-column: 9/11;
      lost-offset: 0/11;

      display: block;
    }

    h2 {
      padding: 40px 0 20px;
      text-align: left;
    }
  }

  &__links {
    border-bottom: solid 1px $c-brand-9;
    list-style: none;
    margin: 0 0 30px;
    padding: 20px 0 4px;
    position: relative;


    @media(--medium-viewport) {
      margin: 0 0 36px;
    }

    @media(--large-viewport) {
      margin: 0 0 30px;
      padding: 2px 0;
    }

    li {
      display: inline-block;
      position: relative;
      margin: 0;

      &:first-of-type {
        left: 0;
      }

      &:last-of-type {
        float: right;
        margin-right: -14px;
      }
    }

    &--saved {
      margin: 0 0 20px;
      padding: 20px 0 9px;

      @media(--large-viewport) {
        padding: 20px 0 15px;
      }

      @media(--wide-viewport) {
        padding: 20px 0 12px;
      }

      li {
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  &__link {
    @extend %small-link;

    line-height: 1;
    padding: 9px 14px;

    @media(--medium-viewport) {
      font-size: rem(14px);
      line-height: 1.57;
    }

    &--back {
        padding-left: 0;

      &:before {
        line-height: 1;
      }
    }
  }

  &__title {
    font-size: rem(16px);
    letter-spacing: rem(1.72px);
    line-height: 1.75;
    padding: 0;
    margin: 0;
    text-align: left;

    @media(--medium-viewport) {
      font-size: rem(24px);
      letter-spacing: rem(2.57px);
      line-height: 1.29;
      margin: 0 0 12px;
    }

    &:after {
      content: none;
    }
  }

  &__price {
    color: $c-brand-2;
    margin: 0 0 30px;

    @media(--medium-viewport) {
      font-size: rem(20px);
      line-height: 1.2;
      margin: 0 0 50px;
    }
  }

  &__form {
    padding: 0 0 40px;

    @media(--wide-viewport) {
      lost-column: 5/6;
    }
  }

  &__save {
    margin: 0 0 18px;
  }

  &__details {
    clear: both;
    overflow: hidden;
    padding: 0 0 30px;
  }

  &__quantity-error {
    color: red;
    font-size: rem(14px);
    font-style: italic;
	}
	
	&__search-form {
		[type="search"] {
			padding: 0 5px;
			margin: 0;
		}

		[type="submit"] {
			padding: 4px 28px;
		}
	}
}

.suggestion {
  lost-waffle: 1/3 3;

  &__category,
  &__title {
    color: $c-brand-2;
    text-align: left;
  }

  &__category {
    font-size: rem(11px);
    line-height: 1.18;
    letter-spacing: 0;
    margin: 0 0 15px;
  }

  &__title {
    font-family: $font-museo;
    font-size: rem(14px);
    letter-spacing: rem(0.3px);
    line-height: 1.43;
    text-transform: none;
  }
}

.tabbed {
  @media(--wide-viewport) {
    display: none;
  }

  &--wide {
    display: none;

    @media(--wide-viewport) {
      display: block;
      overflow: hidden;
      width: 100%;
    }
  }
}

.tabs {
  border-top: solid 1px $c-brand-9;
  list-style: none;
  margin: 0;
  padding: 0;

  @media(--wide-viewport) {
    border-top: 0;
    overflow: hidden;
  }

  &__title {
    font-family: $font-museo;
    letter-spacing: 0;
    line-height: 1.5;
    text-align: left;
    text-transform: none;

    @media(--wide-viewport) {
      text-align: center;
    }


    a:after {
      content: "\e90f";
      display: block;
      font-family: icomoon;
      font-size: rem(7px);
      font-style: normal;
      float: right;
      line-height: 3.45;
      margin-right: 11px;
      vertical-align: middle;

      @media(--wide-viewport) {
        content: none;
      }
    }
  }

  &__section {
    border-bottom: solid 1px $c-brand-9;
    margin: 0;
    padding: 11px 0 17px;

    @media(--wide-viewport) {
      lost-column: 1/3 0 0;

      display: inline-block;
      padding: 10px 0;
    }

    &.open {
      @media(--wide-viewport) {
        border-top: solid 1px $c-brand-9;
        border-left: solid 1px $c-brand-9;
        border-right: solid 1px $c-brand-9;
        border-bottom: 0;
      }
    }
  }

  &__content {
    display: none;
    padding: 10px 0;
    text-align: justify;

    @media(--wide-viewport) {
      border-bottom: solid 1px $c-brand-5;
      padding: 35px 0 20px;
    }

    &.open {
      display: block;
    }
  }

  .open {
    .tabs__title {
      a:after {
        transform: rotateX(180deg);
      }
    }

    .tabs__content {
      display: block;
    }
  }
}

.help {
  padding: 0 0 35px;

  &__title,
  a {
    color: $c-brand-2;
    font-size: rem(16px);
    letter-spacing: 0;
    line-height: 1.5;

  }

  &__title {
    font-family: $font-museo;
    text-align: left;
    text-transform: none;
    margin: 0 0 15px;
  }

  &__email,
  &__phone {
    &:before {
      font-family: icomoon;
      font-size: rem(18px);
      margin-right: 14px;
    }
  }

  &__email {
    &:before {
      content: "\e914";
    }
  }

  &__phone {
    &:before {
      content: "\e915";
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0 0 15px;
  }
}

.added {
  animation: added 1s ease-out forwards;
}

@keyframes added {
  0% { background-color: transparent; }
  50% { background-color: $c-bkg-saveitem; }
  100% { background-color: transparent; }
}