.header {
  background: $c-bkg-main;
  height: 70px;
  overflow: visible;
  padding: 14px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11;

  @media(--medium-viewport) {
    height: 80px;
    padding: 14px 62px;
  }

  &__logo {
    display: inline-block;
    float: left;
    max-width: 280px;

    a {
      color: $c-brand-1;
    }

    span {
      font-size: rem(38px);

      @media(--medium-viewport) {
        font-size: rem(46px);
      }

      &:before {
        content: "\e901";

        @media(--wide-viewport) {
          content: "\e903";
        }
      }
    }
  }

  &__nav {
    background: $c-bkg-main;
    display: none;
    height: calc(100vh - 70px);
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;

    @media(--wide-viewport) {
      background: transparent;
      display: inline-block;
      float: right;
      height: auto;
      left: auto;
      position: relative;
      top: auto;
      width: auto;
    }

    ul {
      margin: 60px 0 0;
      list-style: none;
      padding: 0;
      text-align: center;

      @media(--wide-viewport) {
        display: inline-block;
        float: right;
        margin: 28px 0 0;
        text-align: left;
      }
    }

    li {
      display: block;
      font-family: $font-milo;
      font-size: rem(15px);
      letter-spacing: rem(2px);
      margin: 0 0 27px;
      text-transform: uppercase;
      position: relative;

      @media(--wide-viewport) {
        display: inline-block;
        margin: 0 0 0 42px;
      }

      @media (min-width: 1420px) {
        &:hover {
          .header__nav-submenu {
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }

    a {
      color: $c-brand-5;
      padding-bottom: 5px;
      position: relative;

      &.on {
        &:after {
          border-bottom: solid 1px $c-brand-5;
          content: "";
          left: 50%;
          position: absolute;
          top: 100%;
          transform: translateX(-50%);
          width: 30px;
        }
      }
    }
  }

  &__nav-submenu {
    margin: 15px 0 0 !important;

    li {
      margin: 0 0 15px;
      font-size: 14px;
    }

    @media (min-width: 1420px) {
      transition: 0.2s;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      left: -0.8rem;
      top: 4px;
      float: none !important;
      width: 150px;
      padding: 0.8rem !important;
      background-color: #fff;

      li {
        margin: 0 !important;
      }
    }
  }
}

.menu-prophire {
  display: inline-block;
  position: relative;

  &__counter {
    color: $c-link;
    font-family: $font-museo;
    font-size: rem(14px);
    left: 101%;
    letter-spacing: normal;
    position: absolute;
    top: 10%;

    a {
      color: inherit;
    }
  }
}

