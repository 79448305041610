/* Media Query settings */
@custom-media --small-viewport (min-width: 480px);
@custom-media --medium-viewport (min-width: 768px);
@custom-media --large-viewport (min-width: 1024px);
@custom-media --wide-viewport (min-width: 1420px);

/* LostGrid settings */
@lost gutter 14px;
@lost flexbox no-flex;
@lost cycle none;
@lost clearing both;

.content {
  display: table-row;
  height: 100%;
}

.container {
  lost-utility: clearfix;
  padding: 0 10px;

  @media(--medium-viewport) {
    lost-center: 706px;
    padding: 0;
  }

  @media(--large-viewport) {
    lost-center: 910px;
  }

  @media(--wide-viewport) {
    lost-center: 1300px;
  }

  &--projects {
    @media(--large-viewport) {
      lost-center: 706px;
    }

    @media(--wide-viewport) {
      lost-center: 1008px;
    }
  }

  &--alt {
    padding: 0;
  }

  &--narrow {
    padding: 0 20px;
  }
}