/**
 * -----------------------------------------------------------------------------
 * FUNCTIONS
 * SASS functions.
 * -----------------------------------------------------------------------------
 */
@function em($target, $context: $base-font-size) {
   @if $target == 0 { @return 0 }
   @return $target / $context + 0em;
}

@function rem($size) {
   @if $size == 0 { @return 0 }
   $remSize: $size / $base-font-size;
   @return #{$remSize}rem;
}


