// Classes brought in during headings audit fix
.h1,
.h2,
* .h1,
* .h2 {
  position: relative;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  color: #505961;
}

.h1,
* .h1 {
  font-size: 1.125rem;
  letter-spacing: .08375rem;
  line-height: 1.22;
  margin: 0 0 30px;
  padding: 0 0 18px;
  
  @media only screen and (min-width: 768px) {
    font-size: 1.75rem;
    letter-spacing: .13rem;
    line-height: 1.36;
    margin: 0 0 50px;
    padding: 0 0 18px;
  }

  &::after {
    border-bottom: 1px solid #505961;
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -25px;
    top: 100%;
    width: 50px;
  }
}

.h2,
* .h2 {
  font-size: 1rem;
  letter-spacing: .1075rem;
  line-height: 1.75;
  margin: 0;
  padding: 0;
}