.contact {
  &__section {
    overflow: hidden;

    h1 {
      clear: both;

      @media(--medium-viewport) {
        margin: 0 0 32px;
      }
    }

    p {
      lost-center: 93.33333%;
      text-align: justify;

      @media(--medium-viewport) {
        lost-column: 10/18;
        lost-offset: -4/18;
      }
    }

    address {
      lost-center: 160px;

      padding: 40px 0 20px;
      text-align: center;

      @media(--medium-viewport) {
        lost-center: 195px;

        clear: both;

      }
    }

    &--contact {
      padding: 60px 0 40px;
    }

    &--find {
      padding: 0 0 60px;

      @media(--medium-viewport) {
        padding: 40px 0 100px;
      }
    }
  }

  &__methods {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      text-align: center;
      margin: 0 0 40px;
    }

    a {
      color: $c-brand-2;
      display: block;
    }
  }

  &__social {
    ul {
      @extend %structural-list;
      display: block;
      padding-top: 15px;
      text-align: center;

      @media(--medium-viewport) {
        margin: 0 0 5px;
      }
    }

    li {
      display: inline-block;
      margin: 0;
      padding: 0 17px;
    }

    p {
      text-align: center;
    }
  }

  &__social-pinterest {
    position:relative;
    top: 2px;

    a {
      display: block;
      width: 1.6rem;
      height: 1.6rem;
      background-image: url('/assets/img/icon-pinterest.png');
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;

      &:hover {
        background-image: url('/assets/img/icon-pinterest-hover.png');
      }
    }
  }

  &__icon {
    display: block;
    font-size: rem(18px);
    margin: 0 0 10px;

    &--phone {
      font-size: rem(22px);
    }
  }

  &__findus {
    @media(--medium-viewport) {
      overflow: hidden;
      margin: 0 0 40px;
      padding: 20px 0 0;
    }
  }

  &__map {
    clear: both;
    height: 172px;

    @media(--medium-viewport) {
      height: 380px;
    }

    @media(--wide-viewport) {
      height: 668px;
    }
  }

  &__form {
    clear: both;
    margin: auto;
    max-width: 800px;
    padding: 0 1.1rem;

    label {
      margin: 0 0 0.8ex;
      display: block;
      width: 100%;
    }

    input,
    select,
    textarea {
      &:not(&.btn) {
        display: block;
        width: 100%;
        padding: 2.7ex 1.5ex;
        border: 1px solid #515961;
        border-radius: 0.5ex;
      }
    }

    .btn {
      border-radius: 0;
      width: unset;
      float: none;
      position: relative;
      left: unset;
      transform: unset;
      margin: 0;
      padding: 6px 28px 30px;
      border: 1px solid $c-link;
    }
  }

  &__form-row {}
}

.facebook-icon,
.twitter-icon,
.instagram-icon {
  color: $c-link;
  font-family: icomoon;
  font-size: rem(22px);
}

.instagram-icon {
  width: 23px;
  height: 23px;
  display: block;
  position: relative;
  top: 2px;

  &::before {
    content: "";
    background-image: url('/assets/img/icon-insta-outline.png');
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  &:hover {
    &::before {
      background-image: url('/assets/img/icon-insta.png');
    }
  }
}


.facebook-icon {
  &:before {
    content: "\e916";
  }

  &:hover {
    &:before {
      content: "\e917";
    }
  }
}

.twitter-icon {
  &:before {
    content: "\e918";
  }

  &:hover {
    &:before {
      content: "\e919";
    }
  }
}