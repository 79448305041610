.about {
  display: flex;
  flex-flow: column-reverse;


  @media(--medium-viewport) {
    display: block;
    flex-flow: unset;
    padding: 100px 0 0;
  }

  &__intro {
    padding: 30px 0 0;
    text-align: justify;

    @media(--medium-viewport) {
      lost-column: 12/18;
      lost-offset: -3/18;

      padding: 0 0 40px;
    }

    @media(--wide-viewport) {
      lost-column: 10/18;
      lost-offset: -4/18;
    }

    h1 {
      @media(--medium-viewport) {
        margin: 0 0 30px;
      }
    }
  }

  &__carousel {
    .carousel {
      @media(--medium-viewport) {
        lost-column: 16/18;
        lost-offset: -1/18;
      }

      &__slide {
        img {
          height: 192px !important;
          width: auto !important;

          @media(--medium-viewport) {
            height: 375px !important;
          }

          @media(--wide-viewport) {
            height: 460px !important;
          }
        }
      }

      .slick-slide {
        margin: 0 14px 0 0;
      }

      .slick-dots {
        @media(--medium-viewport) {
          bottom: -35px;
        }

        @media(--wide-viewport) {
          bottom: 10px;
        }
      }
    }
  }
}

.team {
  clear: both;
  display: block;
  list-style: none;
  margin: 0;
  padding: 40px 0;

  @media(--medium-viewport) {
    lost-column: 14/18;
    lost-offset: -2/18;

    padding: 70px 0;
  }

  @media(--wide-viewport) {
    padding: 50px 0;
  }

  li {
    overflow: hidden;

    @media(--medium-viewport) {
      padding: 0 0 40px;
    }
  }

  &__image {
    float: left;
    margin-right: 20px;
    width: 80px;

    @media(--medium-viewport) {
      lost-column: 3/14;
    }
  }

  &__titles {
    border-top: solid 1px $c-brand-5;
    overflow: hidden;
    padding: 10px 0 0;

    @media(--medium-viewport) {
      lost-column: 10/14 1;

      clear: none!important;
      margin-right: 0!important;
      padding: 30px 0 0;
    }
  }

  &__name {
    text-align: left;

    @media(--medium-viewport) {
      font-size: rem(24px);
      letter-spacing: rem(2.57px);
      line-height: 1.29;
      padding: 0 0 30px;
    }

    span {
      display: block;
      padding: 4px 0 0;

      @media(--medium-viewport) {
        display: inline;
      }

      &:before {
        @media(--medium-viewport) {
          content: "| ";
        }
      }
    }
  }

  &__bio {
    clear: both;
    overflow: hidden;
    text-align: justify;

    @media(--medium-viewport) {
      lost-column: 10/14 1;

      clear: none!important;
    }

    p {
      &:first-of-type {
        margin-top: 20px;

        @media(--medium-viewport) {
          margin-top: 0;
        }
      }

      &:last-of-type {
        margin-bottom: 5px;

        @media(--medium-viewport) {
          margin-bottom: 20px;
        }
      }
    }

    .readmore {
      &__button {
        text-align: left;
        margin-left: 100px;

        @media(--medium-viewport) {
          margin-left: 0;
        }

        &.open {
          margin-left: 0;
        }
      }
    }
  }
}